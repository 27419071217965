@font-face {
  font-family: Highman;
  src: url("Highman.5d2a4913.ttf");
}

@font-face {
  font-family: Rubik;
  src: url("Rubik.fc01751e.ttf");
}

body, html {
  margin: 0;
  padding: 0;
}

/*# sourceMappingURL=index.016bbb0f.css.map */
